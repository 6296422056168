.ReactPopup--portal {
  position: fixed;
  left:     0;
  right:    0;
  top:      0;
  bottom:   0;

  display:        flex;
  flex-direction: column;
  pointer-events: none;
}

.ReactPopup--shim {
  position: absolute;
  left:     0;
  right:    0;
  top:      0;
  bottom:   0;
}

.ReactPopup--container {
  position:       absolute;
  pointer-events: none !important;
  display:        flex;
}

.ReactPopup--container-horizontal {
  flex-direction: row;
  align-items:    flex-start;
}
.ReactPopup--container-vertical {
  flex-direction: column;
}
.ReactPopup--container-near {
  justify-content: flex-end;
}
.ReactPopup--container-far {
  justify-content: flex-start;
}

.ReactPopup--body {
  flex:           0 1 auto;
  display:        flex;
  flex-direction: column;
  pointer-events: auto;
}

.ReactPopup--bodyAutoScroll {
  overflow: auto;
}

.ReactPopup--bodyTransition, .ReactPopup--bodyTransition * {
  overflow: hidden !important
}