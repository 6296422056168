.VirtualizedChatView--container {
  overflow: auto;
}

.VirtualizedChatView--list {
  position: relative;
}

.VirtualizedChatView--list > * {
  position: absolute;
  left:     0;
  right:    0;
  bottom:   0;

  will-change: transform;
}