.ModalPortal--root {
  position: fixed;
  top:      0;
  left:     0;
  right:    0;
  bottom:   0;
  
  min-height:     1px;
  pointer-events: none;
  outline:        none;
}

.ModalPortal {
  position: absolute;
  top:      0;
  left:     0;
  right:    0;
  bottom:   0;

  pointer-events: none;
  outline:        none;
}

.ModalPortal--shim {
  position: absolute;
  top:      0;
  left:     0;
  right:    0;
  bottom:   0;

  pointer-events: auto;
}

.ModalPortal--shim.show {
  opacity: 0;
}

.ModalPortal--shim.show-active {
  opacity: 1;
  transition: opacity;
}

.ModalPortal--shim.hide {
  opacity: 1;
}

.ModalPortal--shim.hide-active {
  opacity: 0;
  transition: opacity;
}

.ModalPortal--content {
  position: absolute;
  top:      0;
  left:     0;
  right:    0;
  bottom:   0;
}

.ModalPortal--content > * {
  pointer-events: auto;
}