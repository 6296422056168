.react-notifications--NotificationHost {
  position: absolute;
  top:      0;
  right:    0;
  bottom:   0;
  left:     0;

  pointer-events: none;
}

.react-notifications--NotificationContainer {
  position: absolute;
  top:      0;
  right:    0;
  bottom:   0;
  left:     0;

  pointer-events: none;
  will-change: transform opacity;
}

.react-notifications--NotificationContainer.show {
  transform: translateY(-64px);
  opacity:   0;
}

.react-notifications--NotificationContainer.show-active {
  transform: translateY(0);
  opacity:   1;
}

.react-notifications--NotificationContainer.hide {
  transform: translateY(0);
  opacity:   1;
}

.react-notifications--NotificationContainer.hide-active {
  transform: translateY(-64px);
  opacity:   0;
}

.react-notifications--NotificationContainer.show-active,
.react-notifications--NotificationContainer.hide-active {
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
}