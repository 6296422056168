.react-dnd--DragLayer {
  position: fixed;
  left:     0;
  top:      0;
  right:    0;
  bottom:   0;
  pointer-events: none;
}

.react-dnd--DragLayer * {
  pointer-events: none !important;
}

.react-dnd--DragLayer--ghost {
  position:    absolute;
  will-change: transform, width, height, top, left;
}

.react-dnd--DragLayer--ghost-size {
  position: absolute;
  left:     0;
  top:      0;

  transform-origin: center center;

  display: flex;
  flex-direction: column;
}

.react-dnd--DragLayer--ghost-size.animated {
  transition:          100ms ease-out;
  transition-property: transform, width, height;
}